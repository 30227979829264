/*______________________________*/
import { Translations } from '@iadvize/translations-library';
import { getStaticUrl } from '../shared/staticUrl';
import { GlobalIdz } from '../shared/types/globalTypes';
import { launchFullMode } from './launchFullMode';
import { getTagConfig } from './tagConfig';
import registerPublicProperties, {
  getPublicPropertiesStore,
  registerGettablePublicProperties,
} from './publicMethods/publicProperties/registerPublicProperties';
import {
  initSentry,
  configureSentry,
  getIsSentryLoaded,
  shouldLoadSentry,
  sentryCatchCustomErrors,
  sentryMessageCustomErrors,
} from '../shared/utils/sentry/sentryLauncher';
import { getVisitorContextConfig } from './visitorContextConfig/getVisitorContextConfig';
import initTimers from './visitorState/time';
import { crossEventEmitter } from './crossEventEmitter';
import { getJSCustomData } from './visitorContextConfig/getJSCustomData';
import { getPlatform } from './buildUrl/getPlatformDomain';
import { Vuid, WebsiteId } from '../shared/types/utils';
import { IAdvizeCallbacks } from '../shared/types/callbacks';
import { setIsFromMail } from '../shared/globals';
import getConversationIdFromQueryParams, {
  removeConversationIdParam,
} from '../live/src/getConversationIdFromQueryParams';
import { getStaticConfig } from './visitorContextConfig/getStaticConfig';
import {
  setClientId,
  setTemplateName,
} from './visitorContextConfig/websiteConfiguration';
import {
  getIsAuthorized,
  setIsAuthorized,
} from './visitorState/authorizedState';
import {
  getPublicMethods,
  initIdzInterface,
  registerPublicMethods,
  setPublicMethods,
} from './iAdvizeInterface/iAdvizeInterface';

import get from './publicMethods/get/get';
import help from './publicMethods/help/helpLauncher';
import labs from './publicMethods/labs/labs';
import off from './publicMethods/off/off';
import on from './publicMethods/on/on';
import set from './publicMethods/set/set';
import { setExecutedScoringRuleIds } from './visitorState/executedScoringRuleIds';
import { setExecutedEngagementRules } from './visitorState/executedEngagementRules';
import getTopDomain from '../storage/cookieStorage/getTopDomain';

__webpack_public_path__ = getStaticUrl();

(async () => {
  //________________________________________________________________________________________
  setIsFromMail();
  const conversationId = getConversationIdFromQueryParams();
  removeConversationIdParam();

  const { sid, tpl } = getTagConfig();

  const parsedSid = parseInt(sid as unknown as string, 10);

  //____________________________________________________________
  if (
    window.iAdvize ||
    !parsedSid ||
    !Number.isInteger(parsedSid) ||
    window.location.search.includes('no-iadvize')
  ) {
    return;
  }

  if (!getTopDomain()) {
    //________________________________________
    console.error(
      "iAdvize allowed cookie domains configuration doesn't match current domain",
    );
    return;
  }

  window.iAdvize = {} as GlobalIdz;

  const initialLocation = window.location.href;
  const initialJSCustomData = getJSCustomData();

  const visitorContextConfig = await getVisitorContextConfig({
    initialRequest: true,
    registerNavigation: true,
    sid: parsedSid,
    location: initialLocation,
    customData: initialJSCustomData,
  });

  const commonConfig = visitorContextConfig.config;

  if (commonConfig.authenticationEnabled) {
    setPublicMethods([...getPublicMethods(), 'activate', 'logout']);
  }
  if (commonConfig.debugModeAvailable) {
    setPublicMethods([...getPublicMethods(), 'debug']);
  }

  initIdzInterface();
  registerPublicMethods({
    get,
    help,
    labs,
    off,
    on,
    set,
  });

  setExecutedScoringRuleIds(visitorContextConfig.executedRuleIds || []);
  setExecutedEngagementRules(visitorContextConfig.matchedEngagementRules || []);

  if (shouldLoadSentry(commonConfig.sentry.volume)) {
    initSentry({
      dsn: commonConfig.sentry.dsn,
      environment: getPlatform(),
      maxBreadcrumbs: commonConfig.sentry.maxBreadcrumbs,
      version: process.env.BUILD_IDENTIFIER!,
    });
  }

  setClientId(commonConfig.client_id);

  registerPublicProperties();
  registerGettablePublicProperties();

  initTimers();

  if (commonConfig.templateLayout) {
    await new Promise((resolve) => {
      //_________________________________________________

      if (tpl) {
        commonConfig.templateLayout = commonConfig.templateLayout!.replace(
          /[^/]+\.js/,
          `${tpl}.js`,
        );
      }
      const [staticUrl, templateUrl] =
        commonConfig.templateLayout!.split('js/dist/tpl/');
      setTemplateName(templateUrl.split('.js')[0]);

      const { lang, language } = getTagConfig();
      const curlang = lang || language || navigator.language.slice(0, 2);
      window.iAdvize = {
        ...window.iAdvize,
        callbacks: (window.iAdvizeCallbacks || {}) as IAdvizeCallbacks,
        static_url: staticUrl,
        platform: getPlatform(),
        client_id: commonConfig.client_id,
        website_id: parsedSid as WebsiteId,
        trad: {} as Translations,
        ...(curlang && { curlang: curlang as typeof window.iAdvize.curlang }),
      };
      const script = document.createElement('script');
      script.src = commonConfig!.templateLayout!;
      script.onload = resolve;
      script.onerror = resolve;
      document.head.appendChild(script);
    });
    await window.iAdvize.templateLoadedPromise;
  }

  const { vuid } = visitorContextConfig;
  const publicPropertiesStore = getPublicPropertiesStore();
  publicPropertiesStore.dispatch('visitor:sourceId', vuid as Vuid);

  setIsAuthorized(!commonConfig!.authenticationEnabled);

  if (commonConfig.consentManagementPlatform) {
    const {
      name: cmpName,
      vendorId,
      purposeId,
    } = commonConfig.consentManagementPlatform;

    if (cmpName && vendorId) {
      import(/*______________________________________*/ `./plugins/cmp_${cmpName}`)
        .then((m) => {
          m.default(publicPropertiesStore, { vendorId, purposeId });
        })
        .catch((e) => {
          if (getIsSentryLoaded()) {
            sentryCatchCustomErrors({
              name: 'CMP Plugin Error',
              message: e.message,
            });
          }
        });
    } else if (getIsSentryLoaded()) {
      sentryMessageCustomErrors(
        'CMP config is missing required properties.',
        'warning',
      );
    }
  }

  if (
    (visitorContextConfig.tagVersion === 'FULL' || conversationId) &&
    getIsAuthorized()
  ) {
    const { config: staticConfig } = await getStaticConfig(
      parsedSid,
      commonConfig.language,
    );
    //__________________________________________________________________________________________________________________________________________________________________________
    setTemplateName(staticConfig.template);
    launchFullMode({
      staticConfig,
      vuid,
      conversationId,
      commonConfig: commonConfig!,
    });
    return;
  }

  if (getIsSentryLoaded()) {
    configureSentry({
      environment: getPlatform(),
      vuid: visitorContextConfig.vuid,
      websiteId: parsedSid,
    });
  }

  const { default: lightMode } = await import(
    /*______________________________________*/ '../light'
  );
  lightMode(
    visitorContextConfig.vuid,
    parsedSid,
    visitorContextConfig.htmlCustomDataConfig,
    visitorContextConfig.isDeviceIdEnabled,
    crossEventEmitter,
    launchFullMode,
    commonConfig,
    visitorContextConfig.delayBeforeNextCall,
    initialLocation,
    initialJSCustomData,
    commonConfig.authenticationEnabled,
  );
})();
